<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle> </mainTitle>

          <section class="edp-backend-conditionSearch">
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">{{$t('questionnaireTemplate.templateName')}}</div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="50"
                  v-model="searchParam.title"
                  :placeholder="$t('edpBackendCommon.placeholder')"
                  clearable
                  @change="search"
                />
              </div>
            </div>

            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">{{$t('questionnaireTemplate.creater')}}</div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="50"
                  v-model="searchParam.createByName"
                  :placeholder="$t('edpBackendCommon.placeholder')"
                  clearable
                  @change="search"
                />
              </div>
            </div>

            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{$t('questionnaireTemplate.publishStatus')}}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-select
                  v-model="searchParam.status"
                  clearable
                  :placeholder="$t('edpBackendCommon.select')"
                >
                  <el-option
                    v-for="item in publicStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
            </div>
            <div
              class="edp-backend-conditionSearch-square conditionSearch-square-last"
            >
              <el-button size="small" type="transparent" @click="clearSearch">{{
                $t("edpBackendCommon.clear")
              }}</el-button>
              <el-button size="small" type="primary" @click="search">{{
                $t("edpBackendCommon.search")
              }}</el-button>
            </div>
          </section>

          <section class="edp-backend-common-table">
            <el-table :data="templateData" style="width: 100%">
              <el-table-column prop="code" :label="$t('questionnaireTemplate.templateCode')" width="120"/>
              <el-table-column prop="title" :label="$t('questionnaireTemplate.templateName')"/>
              <el-table-column prop="createTime" :label="$t('questionnaireTemplate.createTime')" width="120">
                <template slot-scope="scope">
                  {{ utils.dateFormatFn(scope.row.createTime, "yyyy.MM.dd")}}
                </template>
              </el-table-column>
              <el-table-column prop="createBy" :label="$t('questionnaireTemplate.creater')" width="120"/>
              <el-table-column prop="isDisable" :label="$t('questionnaireTemplate.isDisable')" width="120">
                <template slot-scope="scope">
                  {{ scope.row.isDisable === 1 ? `${$t('questionnaireTemplate.disable')}` : `${$t('questionnaireTemplate.enable')}` }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('edpBackendCommon.operate')" width="230" fixed="right">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click.native="changeTemplateStatus(scope.row)"
                  >
                    {{ scope.row.isDisable === 1
                        ? $t("questionnaireTemplate.enable")
                        : $t("questionnaireTemplate.disable") }} </el-button>
                        <el-divider direction="vertical"></el-divider>
                  <el-button
                    type="text"
                    @click.native="deleteTemplate(scope.row)"
                  >
                    {{ $t("edpBackendCommon.delete") }} </el-button>
                      <el-divider direction="vertical"></el-divider>
                  <el-button
                    type="text"
                    @click.native="$router.push({ path: 'backend-template-detail', query: {id: scope.row.id}})"
                  >{{$t('questionnaireTemplate.viewDetail')}}</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="table-footer fjsb">
              <span class="table-pagination-text">{{ total }} {{$t('edpBackendCommon.totalRecords')}}, {{ totalPage }}  {{$t('edpBackendCommon.pages')}}</span>
              <el-pagination
                :total="total"
                :current-page="pageNo"
                background
                hide-on-single-page
                layout="prev, pager, next"
                @current-change="currentChange"
              />
            </div>
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "questionnaireTemplate",
  data() {
    return {
      times: ["", ""],
      showShare: false,
      searchParam: {
        title: '',
        createByName: '',
        status: '',
        startTime: '',
        endTime: '',
      },
      pageSize: 10,
      total: 0,
      pageNo: 1,
      totalPage: 0,
      publicStatusList: [
        {
          label: this.$t('questionnaireTemplate.enable'),
          value: 0,
        },
        {
          label: this.$t('questionnaireTemplate.disable'),
          value: 1,
        },
      ],
      templateData: [],
      dateils: {
        pageAddress: "string",
        publishSystems: "string",
        qrUrl: "string",
      },
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(o, n) {
        this.intiData();
      },
    },
    times(val) {
      console.log(val);
      if (val) {
        this.searchParam.startTime = val[0];
        this.searchParam.endTime = val[1];
      }
    },
  },
  mounted() {},
  created() {},
  methods: {
    ...mapActions({
      templateList: "questionnaireTemplate/templateList",
      surveyView: "questionnaireTemplate/surveyView", 
      turnoffTemplate: "questionnaireTemplate/turnoffTemplate",
      turnonTemplate: "questionnaireTemplate/turnonTemplate",
      templateDelete: "questionnaireTemplate/templateDelete",
      surveyDelete: "questionnaireTemplate/surveyDelete",
      offlineQuestion: "questionnaireTemplate/offlineQuestion",
      onlineQuestion: "questionnaireTemplate/onlineQuestion",
      turnoffQuestion: "questionnaireTemplate/turnoffQuestion",
      turnonQuestion: "questionnaireTemplate/turnonQuestion",
      surveyQrcode: "questionnaireTemplate/surveyQrcode"
    }),
    open() {
      window.open('/#/backend-questionnaire-preview', '_blank');
    },
    clearSearch() {
      this.searchParam.title = '';
      this.searchParam.createByName = '';
      this.searchParam.status = '';
      this.searchParam.startTime = '';
      this.searchParam.endTime = '';
      this.pageNo = 1;
      this.times = ["", ""];
    },
    search() {
      this.pageNo = 1;
      this.intiData();
    },
    currentChange(num) {
      this.pageNo = num;
      this.intiData();
    },
    intiData() {
      const params = {
        current: this.pageNo,
        size: this.pageSize,
        createByName: this.searchParam.createByName,
        title: this.searchParam.title,
        isDisable: this.searchParam.status,
      };
      this.templateList(params).then((res) => {
        if (res.success === true) {
          this.templateData = res.data;
          this.total = res.total;
          this.totalPage = res.totalPage;
        }
      });
    },
    changeTemplateStatus(row) {
      let str = `${this.$t("questionnaireTemplate.enable")}`;
      if (row.isDisable === 1) {
        str = `${this.$t("questionnaireTemplate.enable")}`;
      } else {
        str = `${this.$t("questionnaireTemplate.disable")}`;
      }
      this.$confirm(`${this.$t('edpBackendCommon.confirm')} ${str} ${this.$t('questionnaireTemplate.thisTemplate')}`, this.$t('edpBackendCommon.noticeTitle'), {
        confirmButtonText: str,
        cancelButtonText: this.$t('edpBackendCommon.return'),
        center: false,
      })
        .then(() => {
          if (row.id) {
            if (row.isDisable === 0) {
              this.turnoffTemplate({ id: row.id }).then((res) => {
                if (res.success === true) {
                  this.pageNo = 1;
                  this.$message({
                    type: "success",
                    message: this.$t("edpBackendCommon.actionSuccess"),
                  });
                  this.intiData();
                }
              });
            } else {
              this.turnonTemplate({ id: row.id }).then((res) => {
                if (res.success === true) {
                  this.pageNo = 1;
                  this.$message({
                    type: "success",
                    message: this.$t("edpBackendCommon.actionSuccess"),
                  });
                  this.intiData();
                }
              });
            }
          }
        })
        .catch(() => {});
    },
    deleteTemplate(row) {
      this.$confirm(`${this.$t('edpBackendCommon.delete')} ${this.$t('questionnaireTemplate.thisTemplate')}`, this.$t('edpBackendCommon.noticeTitle'), {
        confirmButtonText: this.$t('edpBackendCommon.delete'),
        cancelButtonText: this.$t('edpBackendCommon.return'),
        center: false,
      })
        .then(() => {
          if (row.id) {
            this.templateDelete(row.id).then((res) => {
              if (res.success === true) {
                this.pageNo = 1;
                this.$message({
                  type: "success",
                  message: this.$t('edpBackendCommon.actionSuccess'),
                });
                this.intiData();
              }
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
.searchTitle {
  text-align: left;
  margin-top: 16px;
  img {
    position: absolute;
    margin-top: 2px;
  }
  span {
    margin-left: 20px;
  }
}
.flex-buttons {
  top: 14px;
}
.searchBtn {
  text-align: right;
}
.mt20 {
  margin-top: 16px;
}
.row {
  margin-bottom: 18px;
}
.ftClas {
  color: #3E886D;
  cursor: pointer;
}
.share-wrap {
  border-radius: 10px;
  >>> .el-dialog__header {
    background: rgb(242, 242, 242);
    .titles {
      background: rgb(242, 242, 242);
      width: 90%;
      font-size: 14px;
      color: rgb(0, 9, 91);
      text-align: right;
      i {
        font-size: 14px;
      }
    }
    .el-dialog__close:before {
      font-size: 22px;
      color: #333;
    }
  }
  h4 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  .desc {
    font-size: 14px;
    color: rgb(110, 110, 110);
    text-align: center;
  }
  .qrcode {
    display: block;
    width: 140px;
    height: 140px;
    margin: 10px auto;
    padding: 4px;
    border: 1px solid rgb(153, 153, 153);
  }
  .links {
    text-align: center;
    font-size: 14px;
    color: rgb(153, 153, 153);
    span {
      font-size: 12px;
      color: rgb(23, 0, 244);
      text-decoration: underline;
    }
  }
  .copy-btn {
    display: block;
    margin: 5px auto;
  }
}
</style>
<style lang="less">
.questionnaires {
  button {
    cursor: pointer;
  }
  .el-dropdown-link {
    cursor: pointer;
  }
  .el-icon-arrow-down {
    font-size: 14px;
  }
}
</style>
